/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid mb-5">
    <div class="container- mt-1 mb-5">
      <template>
        <div class="bg- mt-4">
          <div
            class="d-flex bg-grey flex-wrap mb-4 justify-content-between align-items-center"
          >
            <div class="my-1 flex-fill">
              <h4 class="d-flex" v-if="viewMode == 'PRISE_EN_CHARGE'">
                Prise en charge de {{ de.prenom }}
                <azoli-status-badge :de="de" class="ms-2" />
              </h4>
              <h4 class="d-flex" v-else>
                Liste des activités de {{ de.prenom }}
                <azoli-status-badge :de="de" class="ms-2" />
              </h4>
            </div>
            <DemandeurMutationDialog
              ref="demandeurMutationDialog"
              :demandeur="demandeur"
              v-if="isReady && $can('MUTER_DEMANDEUR', 'FIN_OC')"
              @save="saveMutationDemandeur"
            />
            <GelerParcoursDemandeurDialog
              ref="gelerParcoursDemandeurDialog"
              :demandeur="demandeur"
              v-if="
                isReady &&
                ($can('GELER', 'PRISE_EN_CHARGE') || $can('GELER', 'PARCOURS_DEMANDEUR'))
              "
              @save="saveGelerParcoursDemandeur(demandeur)"
            />
            <activite-editor
              ref="activiteEditor"
              :item="activeEvent"
              @saved="createOrUpdateProgramme"
              :single-participant-id="demandeurId"
              btnText="Programmer une activité"
              title="Programmer une activite"
            />
            <button
              v-tooltip.top="
                'Changer le niveau d\'instruction pour le faire passer PPI/INSTRUIT vice-versa'
              "
              :disabled="!isReady || readOnly"
              class="btn btn-outline-info style_btn me-4"
              @click="showDemandeurTransformationDialog"
              v-if="$can('MUTER_DEMANDEUR', 'FIN_OC')"
            >
              Mutation du DE
            </button>
            <button
              v-tooltip.top="'Permet de changer de le parcours de DE'"
              :disabled="!isReady || readOnly"
              v-if="
                $can('GELER', 'PRISE_EN_CHARGE') || $can('GELER', 'PARCOURS_DEMANDEUR')
              "
              class="btn btn-outline-danger style_btn me-4"
              @click="showGelerParcoursDemandeurDialog"
            >
              Geler parcours DE
            </button>
            <button
              :disabled="!isReady || readOnly"
              v-tooltip.top="'Programmer une activité'"
              class="btn btn-warning style_btn"
              @click="ajouterProgramme"
            >
              Programmer une activité
            </button>
            <div v-if="$can('followUp', 'Activite')" class="btn-group ms-4">
              <PSplitButton
                label="Exporter"
                :model="exportActions"
                class="p-button-outlined p-button-primary"
              />
            </div>
            <div class="d-flex justify-content-end mb-2 mt-2 ms-4">
              <span class="p-buttonset">
                <PButton
                  :disabled="readOnly"
                  :class="{ 'p-button-outlined': viewMode != 'PRISE_EN_CHARGE' }"
                  @click.prevent.stop="viewMode = 'PRISE_EN_CHARGE'"
                  >Prise En Charge</PButton
                >
                <PButton
                  :disabled="readOnly"
                  :class="{ 'p-button-outlined': viewMode != 'ACTIVITE_LIST' }"
                  @click.prevent.stop="viewMode = 'ACTIVITE_LIST'"
                  >Liste des activités</PButton
                >
              </span>
            </div>
          </div>

          <div class="card card-body border-0 mx-1" v-if="viewMode == 'ACTIVITE_LIST'">
            <template>
              <div
                v-for="(act, i) in activitePlanifies"
                :key="i"
                class="d-flex justify-content-between border-light align-items-start mb-2 p-2 pb-1"
              >
                <span>
                  <h6>
                    {{ i + 1 }}. {{ act.titre }}-{{ act.typeActivite.code }} - N°000{{
                      act.id
                    }}
                    du {{ act.datePrevue | moment("DD-MM-YYYY HH:mm") }}
                  </h6>
                  <!-- <PBadge :value="act.statusActivite" ></PBadge> -->
                  <span class="px-2 py-1 round-4" :class="activiteColor(act)">{{
                    act.statusActivite
                  }}</span>
                </span>
                <div>
                  <ActiviteReportEditor
                    :activite="activeEvent"
                    ref="activiteReportEditor"
                    @save="createOrUpdateRapport"
                    :readOnly="readOnly"
                    title="Rapport de participation"
                  />
                  <a
                    v-if="!readOnly"
                    class="btn btn-primary btn-sm"
                    @click.prevent="remplirRapport(act, i)"
                    href="#"
                    ><i class="pi pi-book"></i> RAPPORT
                  </a>
                  <!-- <a class="btn btn-light" @click.prevent="$refs.evadaDialog.show()" href="#"><i class="bi bi-eye"></i> EVADA</a> -->
                </div>
              </div>
            </template>
          </div>
          <div class="card border-0 mb-2" v-if="viewMode == 'PRISE_EN_CHARGE'">
            <DStepper
              :steps="steps"
              :activeStepIndex="activeStepIndex"
              :readonly="false"
              class=""
            />
          </div>
          <p-progress-bar v-if="!isReady" style="height: 0.33em" mode="indeterminate" />
          <div class="car bg-danger mb-2">
            <div
              v-if="isReady && priseEnCharge.statutParcoursDemandeur == 'GELE'"
              class="alert alert-danger"
              closable
            >
              Cette prise en charge est actuellement
              <PBadge severity="danger" value="GELE"></PBadge>. <br />
              Toute modification effectuée sur elle ne sera pas pris en compte.
            </div>
            <div v-if="isReady && readOnly" class="alert alert-warning" closable>
              Attention cette prise en charge est en mode consultation. Aucune
              modification n'est autorisée.
            </div>
          </div>
          <div class="card card-body border-0 mx-1" v-if="viewMode == 'PRISE_EN_CHARGE'">
            <!-- "DEGRE D'AUTONOMIE" -->
            <div v-if="activeStepIndex == 0">
              <PCard class="shadow-none">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>Dégré d'autonomie</span>
                    <!-- <a class="btn btn-light" href=""><i class="pi pi-caret-right"></i> EVADA</a> -->
                  </div>
                </template>
                <template #subtitle v-if="getEntretienDiagnostics.length != 0">
                  <div
                    v-if="priseEnCharge && priseEnCharge.classement"
                    class="d-flex justify-content-between"
                  >
                    <span class="mb-2">
                      <h6>
                        <PTag
                          :value="priseEnCharge.classement.libelle"
                          :severity="getClassementColor(priseEnCharge.classement.code)"
                        ></PTag>
                      </h6>
                      <small>{{ priseEnCharge.classement.description }}</small>
                    </span>
                    <div>
                      <!-- <a class="btn btn-light" @click.prevent="$refs.evadaDialog.show()" href="#"><i class="bi bi-eye"></i> EVADA</a> -->
                    </div>
                  </div>
                  <template>
                    <div
                      v-for="(act, i) in getEntretienDiagnostics"
                      :key="i"
                      class="d-flex justify-content-between border-light align-items-start mb-2 p-2 pb-1"
                    >
                      <span>
                        <h6>
                          {{ i + 1 }}. {{ act.titre }}-{{ act.typeActivite.code }} -
                          N°000{{ act.id }} du
                          {{ act.datePrevue | moment("DD-MM-YYYY HH:mm") }}
                        </h6>
                        <!-- <PBadge :value="act.statusActivite" ></PBadge> -->
                        <span class="px-2 py-1 round-4" :class="activiteColor(act)">{{
                          act.statusActivite
                        }}</span>
                      </span>
                      <div>
                        <ActiviteReportEditor
                          :activite="activeEvent"
                          ref="activiteReportEditor"
                          :readOnly="readOnly"
                          @save="createOrUpdateRapport"
                          title="Rapport de participation"
                        />
                        <a
                          v-if="!readOnly"
                          class="btn btn-primary btn-sm"
                          @click.prevent="remplirRapport(act, i)"
                          href="#"
                          ><i class="pi pi-book"></i> RAPPORT
                        </a>
                        <!-- <a class="btn btn-light" @click.prevent="$refs.evadaDialog.show()" href="#"><i class="bi bi-eye"></i> EVADA</a> -->
                      </div>
                    </div>
                  </template>
                </template>

                <template #content v-else>
                  <template v-if="de.status == 'VALIDE'">
                    <h6>
                      La prise en charge de {{ de.nom }} {{ de.prenom }} n'a pas encore
                      démarrée.
                    </h6>
                    <div class="mb-2">
                      Commencez en le programmant pour un entretien diagnostic.
                    </div>
                    <PButton
                      label="Programmer un entretien"
                      v-if="!readOnly"
                      @click="ajouterProgramme('ENTRETIEN_DIAGNOSTIC')"
                    />
                  </template>
                  <template v-else>
                    <DossierValidationDialog
                      ref="dossierValidator"
                      :demandeur="demandeur"
                      @save="validerFiche"
                    />

                    <h6>
                      La prise en charge de {{ de.nom }} {{ de.prenom }} ne peut pas
                      démarrer tant que {{ de.estPPI ? "sa fiche " : "son cv" }} n'est pas
                      <PTag severity="danger">VALIDE</PTag>.
                    </h6>
                    <div class="mb-2">
                      Commencez en procédant à la validation de son dossier.
                    </div>
                    <PButton
                      v-if="!readOnly"
                      class="p-button p-button-danger"
                      label="Procéder à la validation"
                      @click.prevent="showDossierValidationDialog(de)"
                    />
                  </template>
                </template>
              </PCard>
              <PDivider />
              <PCard
                v-if="priseEnCharge != null && priseEnCharge.classement"
                class="shadow-none mt-"
              >
                <template #title>
                  <div class="d-flex justify-content-between">
                    <!-- <span>Modules exigés par le dégré d'autonomie du DE</span> -->
                    <span
                      >Activités indispensables pour apprécier le dégré d'autonomie du
                      DE</span
                    >
                  </div>
                </template>
                <template #content>
                  <div v-if="getAtelierPPs.length == 0">
                    <ActiviteRequisSelectorDialog
                      ref="activiteRequisEditor"
                      @save="creationModeSelected"
                      title="'Sélection du mode de programmation'"
                      :single-participant-id="demandeurId"
                    />
                    <h6 v-if="priseEnCharge.classement.code == 'DENA'">
                      Le dégé d'autonomie,
                      <PTag>{{ priseEnCharge.classement.libelle }}</PTag> (<span
                        class="text-muted"
                        >{{ priseEnCharge.classement.description }}</span
                      >), du DE {{ de.nom }} {{ de.prenom }} exige les modules de
                      <span class="fw-bold">Connaissance de soi</span> et
                      <span class="fw-bold">Exploration du marché</span>.
                    </h6>
                    <h6 v-else-if="priseEnCharge.classement.code == 'DESA'">
                      Le dégé d'autonomie,
                      <PTag>{{ priseEnCharge.classement.libelle }}</PTag> (<span
                        class="text-muted"
                        >{{ priseEnCharge.classement.description }}</span
                      >), du DE {{ de.nom }} {{ de.prenom }} exige le modules de
                      <span class="fw-bold">Exploration du marché</span>.
                    </h6>
                    <div class="mb-2">Commencez en le programmant pour ces modules.</div>
                    <PButton
                      v-if="!readOnly"
                      label="Programmer un entretien"
                      @click="programmerModuleRequis"
                    />
                  </div>
                  <div v-else>
                    <template>
                      <div
                        v-for="(act, i) in getAtelierPPs"
                        :key="i"
                        class="d-flex justify-content-between border-light align-items-start mb-2 p-2 pb-1"
                      >
                        <span>
                          <h6>
                            {{ i + 1 }}. {{ act.titre }}-{{ act.typeActivite.code }} -
                            N°000{{ act.id }} du
                            {{ act.datePrevue | moment("DD-MM-YYYY HH:mm") }}
                          </h6>
                          <div class="d-flex justify-content-between">
                            <span class="px-2 py-1 round-4" :class="activiteColor(act)">{{
                              act.statusActivite
                            }}</span>
                            <div>
                              <PBadge
                                v-for="t in act.tags"
                                :key="t.id"
                                :value="t.libelle"
                                class="ms-2"
                              ></PBadge>
                            </div>
                          </div>
                        </span>
                        <div>
                          <ActiviteReportEditor
                            :activite="activeEvent"
                            ref="activiteReportEditor"
                            @save="createOrUpdateRapport"
                            :readOnly="readOnly"
                            title="Rapport de participation"
                          />
                          <a
                            class="btn btn-primary btn-sm"
                            v-if="!readOnly"
                            @click.prevent="remplirRapport(act, i)"
                            href="#"
                            ><i class="pi pi-book"></i> RAPPORT
                          </a>
                          <!-- <a class="btn btn-light" @click.prevent="$refs.evadaDialog.show()" href="#"><i class="bi bi-eye"></i> EVADA</a> -->
                        </div>
                      </div>
                    </template>
                    <!-- <template v-if="deClassement == 'DENA'">
                        <PDivider />
                        <PCard class="shadow-none mt-">
                          <template #title>
                            <div class="d-flex justify-content-between">
                              <span>Connaissance de Soi</span>
                            </div>
                          </template>
                          <template #subtitle>
                            <StatutProfesssionelSelectorDialog :title="'Sélection du statut professionnel'"  v-if="priseEnCharge" ref="statutProfessionnelSelector" @saved="updateStatutProfessionnel" v-model="priseEnCharge.statutProfessionnel" />
                          </template>
                        </PCard>
                      </template>
      
                      <template v-if="deClassement == 'DENA'">
                        <PDivider />
                        <PCard class="shadow-none mt-" >
                          <template #title>
                            <div class="d-flex justify-content-between">
                              <span>Exploration de marché</span>
                            </div>
                          </template>
                          <template #subtitle>
                            <StatutProfesssionelSelectorDialog :title="'Sélection du statut professionnel'"  v-if="priseEnCharge" ref="statutProfessionnelSelector" @saved="updateStatutProfessionnel" v-model="priseEnCharge.statutProfessionnel" />
                          </template>
                        </PCard>
                      </template> -->
                  </div>
                </template>
              </PCard>
              <PDivider v-if="priseEnCharge != null && priseEnCharge.classement" />
              <PCard class="shadow-none mt-">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>Statut professionnel</span>
                    <!-- <a class="btn btn-light" href=""><i class="bi bi-eye"></i> EVADA</a> -->
                  </div>
                </template>
                <template #subtitle>
                  <StatutProfesssionelSelectorDialog
                    :title="'Sélection du statut professionnel'"
                    v-if="priseEnCharge"
                    :readOnly="readOnly"
                    ref="statutProfessionnelSelector"
                    @saved="updateStatutProfessionnel"
                    v-model="priseEnCharge.statutProfessionnel"
                  />
                  <div class="d-flex justify-content-between">
                    <span
                      v-if="priseEnCharge && priseEnCharge.statutProfessionnel == null"
                    >
                      <h6>{{ "Pas encore défini" }}</h6>
                      <!-- <small>{{ 'Date de positionnement' }}</small> -->
                    </span>
                    <span v-else-if="priseEnCharge">
                      <h6><PTag :value="priseEnCharge.statutProfessionnel.libelle" /></h6>
                      <!-- <small>{{ 'Date de positionnement' }}</small> -->
                    </span>
                    <div>
                      <a
                        v-if="!readOnly"
                        class="btn btn-sm btn-outline-danger btn_style"
                        @click.prevent="$refs.statutProfessionnelSelector.show()"
                        href="#"
                        ><i class="bi bi-pencil"></i
                      ></a>
                    </div>
                  </div>
                </template>
              </PCard>
              <PDivider />
              <PCard class="shadow-none border-1">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>Commentaire</span>
                    <div v-if="priseEnCharge">
                      <TextEditorDialog
                        ref="commentaireAutonomieEditor"
                        :title="'Commentaire sur le dégré d\'autonomie'"
                        @saved="updateCommentaireAutonomie"
                        v-if="priseEnCharge"
                        :readOnly="readOnly"
                        v-model="priseEnCharge.commentaireDegreAutonomie"
                      />
                      <a
                        v-if="!readOnly"
                        class="btn btn-outline-danger btn-sm"
                        @click.prevent="$refs.commentaireAutonomieEditor.show()"
                        href="#"
                        ><i class="bi bi-pencil"></i
                      ></a>
                    </div>
                  </div>
                </template>
                <template #subtitle>
                  <div class="d-flex justify-content-between">
                    <span
                      v-if="
                        priseEnCharge && priseEnCharge.commentaireDegreAutonomie == null
                      "
                    >
                      <h6>{{ "Aucun commentaire" }}</h6>
                    </span>
                    <span
                      v-else-if="priseEnCharge"
                      v-html="priseEnCharge.commentaireDegreAutonomie"
                    ></span>
                  </div>
                </template>
              </PCard>
            </div>

            <!-- "POSITIONNEMENT"  -->
            <div v-if="activeStepIndex == 1">
              <PCard class="shadow-none">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>1. Appréciation</span>
                    <TextEditorDialog
                      ref="appreciationEditor"
                      :title="'Appréciation'"
                      @saved="updateAppreciation"
                      v-if="priseEnCharge"
                      v-model="priseEnCharge.appreciation"
                    />
                    <a
                      v-if="!readOnly"
                      class="btn btn-outline-danger btn-sm"
                      @click.prevent="$refs.appreciationEditor.show()"
                      href=""
                      ><i class="bi bi-pencil"></i
                    ></a>
                  </div>
                </template>
                <template #subtitle>
                  <div class="d-flex justify-content-between">
                    <span
                      v-if="priseEnCharge == null || priseEnCharge.appreciation == null"
                    >
                      <h6>Aucun contenu à afficher</h6>
                      <!-- <small>{{ classement.description }}</small> -->
                    </span>
                  </div>
                </template>
              </PCard>
              <PDivider />
              <PCard class="shadow-none">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>2. Mise à jour des compétences</span>
                    <CvDetailsDialog
                      ref="cvDetailsDialog"
                      :demandeurId="demandeurId"
                      v-model="demandeur"
                      @save="handleCvChanged"
                    />
                    <a
                      v-if="!readOnly"
                      class="btn btn-sm btn-outline-primary btn_style me-2"
                      title="Voir le CV"
                      @click.prevent="$refs.cvDetailsDialog.show()"
                      href="#"
                      ><i class="bi bi-journal-richtext"></i
                    ></a>
                    <!-- <a class="btn btn-sm btn-outline-danger btn_style" title="Modifier le CV" @click.prevent="$refs.evadaDialog.show()" href="#"><i class="bi bi-pencil"></i></a> -->
                    <!-- <a class="btn btn-light" href=""><i class="bi bi-eye"></i> EVADA</a> -->
                  </div>
                </template>
                <template #subtitle>
                  <div class="d-flex justify-content-between">
                    <span v-if="demandeur && demandeur.emplois.length == 0">
                      <h6>{{ "Pas encore défini" }}</h6>
                      <!-- <small>{{ 'Date de positionnement' }}</small> -->
                    </span>
                    <!-- <div>
                        <CvDetailsDialog ref="cvDetailsDialog" :demandeurId="demandeurId" @save="handleCvChanged"/>
                        <a class="btn btn-sm btn-outline-primary btn_style me-2" title="Voir le CV" @click.prevent="$refs.cvDetailsDialog.show()" href="#"><i class="bi bi-journal-richtext"></i></a>
                        <a class="btn btn-sm btn-outline-danger btn_style" title="Modifier le CV" @click.prevent="$refs.evadaDialog.show()" href="#"><i class="bi bi-pencil"></i></a>
                      </div> -->
                  </div>
                </template>
                <template #content>
                  <block-emplois
                    v-if="demandeur && demandeur.emplois"
                    v-model="demandeur.emplois"
                    @save="updateDemandeurEmplois"
                    :displayMode="'pretty'"
                  >
                    <template #header="{ addEmploi }">
                      <div class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                        Expérience professionnelle
                      </div>
                      <a
                        v-if="!readOnly"
                        @click.prevent="addEmploi"
                        class="btn btn-primary rounded-0 btn-sm"
                        ><i class="bi bi-clipboard-plus"></i
                      ></a>
                    </template>
                  </block-emplois>

                  <!-- <block-competences
                    v-if="demandeur && demandeur.competences"
                    v-model="demandeur.competences"
                    @save="updateDemandeurCompetences"
                    :displayMode="'nice'"
                  >
                    <template #header="{ addEmploi }">
                      <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                        Expérience professionnelle
                      </h5>
                      <a
                        @click.prevent="addEmploi"
                        class="btn btn-primary rounded-0 btn-sm"
                        ><i class="bi bi-clipboard-plus"></i
                      ></a>
                    </template>
                  </block-competences> -->
                </template>
              </PCard>
              <PDivider />
              <PCard class="shadow-none">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span
                      >3. Métier(s) recherché(s)
                      <PTag v-if="priseEnCharge"
                        >{{
                          priseEnCharge ? priseEnCharge.propositionMetiers.length : "-"
                        }}
                        proposés</PTag
                      ></span
                    >
                    <div v-if="priseEnCharge">
                      <PropositionMetier
                        :item="priseEnCharge.propositionMetiers"
                        :demandeur="demandeur"
                        :title="'Sélecteur de métier'"
                        ref="propositionMetierDialog"
                        @save="savePropositionMetiers"
                      />
                      <a
                        v-if="!readOnly"
                        class="btn btn-sm btn-outline-danger btn_style"
                        @click.prevent="$refs.propositionMetierDialog.show()"
                        href="#"
                        ><i class="bi bi-pencil"></i
                      ></a>
                    </div>
                  </div>
                </template>
                <template #content>
                  <div class="d-flex justify-content-between">
                    <span
                      v-if="priseEnCharge && priseEnCharge.propositionMetiers.length == 0"
                    >
                      <h6>{{ "Aucune proposition" }}</h6>
                      <!-- <small>{{ 'Date de positionnement' }}</small> -->
                    </span>
                    <template v-else-if="priseEnCharge">
                      <div class="w-100">
                        <div
                          class="shadow-none py-0 border-light mb-1"
                          v-for="(metier, i) in priseEnCharge.propositionMetiers"
                          :key="metier.id"
                        >
                          <div class="card-body">
                            <h6>{{ i + 1 }}. {{ metier.libelle }}</h6>
                            <span>{{ metier.famille.libelle }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </PCard>
              <PDivider />
              <PCard class="shadow-none">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>4. Positionnement</span>
                    <div v-if="priseEnCharge">
                      <DecisionSelectorDialog
                        :title="'Décision de positionnement'"
                        ref="decisionSelector"
                        @saved="updateCheminEmploi"
                        :item="priseEnCharge.cheminEmploi"
                        :demandeur="de"
                      />
                      <a
                        v-if="!readOnly"
                        class="btn btn-sm btn-outline-danger btn_style"
                        @click.prevent="decisionEditor"
                        href="#"
                        ><i class="bi bi-pencil"></i
                      ></a>
                    </div>
                  </div>
                </template>
                <template #subtitle>
                  <div class="d-flex justify-content-between">
                    <span
                      v-if="priseEnCharge == null || priseEnCharge.cheminEmploi == null"
                    >
                      <h6>{{ "Aucune décision" }}</h6>
                    </span>
                    <div v-else>
                      <div><PTag :value="priseEnCharge.cheminEmploi" /></div>
                      <span
                        >Le demandeur est positionné pour l'emploi
                        {{ priseEnCharge.cheminEmploi }}
                      </span>
                      <PBadge
                        severity="warning"
                        v-if="priseEnCharge.datePositionnement != null"
                        v-tooltip.top="
                          $moment(priseEnCharge.datePositionnement).format('DD/MM/YYYY')
                        "
                        :value="
                          $moment(priseEnCharge.datePositionnement).format('DD/MM/YYYY')
                        "
                      ></PBadge>
                    </div>
                  </div>
                </template>
              </PCard>
            </div>

            <!-- "PLAN D'ACTION" -->
            <div v-if="activeStepIndex == 2">
              <!-- <PCard class="shadow-none">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <div
                      v-if="
                        priseEnCharge &&
                        priseEnCharge.cheminEmploi != null &&
                        priseEnCharge.activeParcoursDemandeur != null
                      "
                      class="d-flex justify-content-between"
                    >
                      <span v>Parcours () </span>
                      <PButton
                      v-if="!readOnly"
                        :key="p.id"
                        class="p-button-rounded p-button-outlined"
                        v-for="(p, i) in priseEnCharge.parcoursDemandeurs"
                      >
                        Parcours {{ i + 1 }}
                      </PButton>
                    </div>
                  </div>
                </template>
                <template #subtitle> </template>
              </PCard> -->
              <PCard class="shadow-none">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>1. Presciptions recommandées (Décision) </span>

                    <button
                      class="btn btn-sm btn-success"
                      :disabled="readOnly"
                      v-tooltip.top="'Actualiser la liste des prescriptions'"
                      @click.prevent="generationDesPrescriptions"
                    >
                      <i class="bi bi-arrow-clockwise"></i> Actualiser
                    </button>
                  </div>
                </template>
                <template #subtitle>
                  <div
                    v-if="
                      priseEnCharge &&
                      priseEnCharge.cheminEmploi != null &&
                      priseEnCharge.prescriptions.length == 0
                    "
                    class="d- justify-content-between px-4"
                  >
                    <div>
                      <div class="mb-2">
                        <h6>
                          {{ de.prenom }} a été orienté vers l'emploi
                          <span> {{ priseEnCharge.cheminEmploi }} </span>
                        </h6>
                        <small
                          >Générer lui des prescriptions selon le modèle standard de
                          l'emploi
                          <span class="fw-bold"> {{ priseEnCharge.cheminEmploi }} </span>
                          pour les
                          <span class="fw-bold" :severity="de.estPPI ? 'danger' : ''"
                            >{{ de.categorieDemandeur }}s</span
                          >.</small
                        >
                      </div>
                      <a
                        :disabled="readOnly"
                        class="btn btn-primary"
                        @click.prevent="generationDesPrescriptions"
                        href="#"
                      >
                        Générer les prescriptions</a
                      >
                    </div>
                  </div>
                  <Prescriptions
                    v-else-if="priseEnCharge && priseEnCharge.prescriptions.length != 0"
                    ref="prescriptionEditor"
                    v-model="priseEnCharge.prescriptions"
                    :demandeur="demandeur"
                    :readOnly="readOnly"
                    :priseEnCharge="priseEnCharge"
                  />
                </template>
              </PCard>
              <PDivider />
              <PCard class="shadow-none mt-">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>2. Commentaire</span>
                    <div v-if="priseEnCharge">
                      <TextEditorDialog
                        ref="commentairePlanActionEditor"
                        :title="'Commentaire sur le plan d\'action'"
                        @saved="updateCommentairePlanAction"
                        v-if="priseEnCharge"
                        v-model="priseEnCharge.commentairePlanAction"
                      />
                      <a
                        v-if="!readOnly"
                        class="btn btn-outline-danger btn-sm"
                        @click.prevent="$refs.commentairePlanActionEditor.show()"
                        href=""
                        ><i class="bi bi-pencil"></i
                      ></a>
                    </div>
                  </div>
                </template>
                <template #subtitle>
                  <div class="d-flex justify-content-between">
                    <span
                      v-if="
                        priseEnCharge == null ||
                        priseEnCharge.commentairePlanAction == null
                      "
                    >
                      <h6>{{ "Pas encore défini" }}</h6>
                      <small>{{ "Date de positionnement" }}</small>
                    </span>
                    <div v-else v-html="priseEnCharge.commentairePlanAction"></div>
                  </div>
                </template>
              </PCard>
              <PDivider />
              <PCard class="shadow-none mt-">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span>3. Consignes</span>
                    <!-- <a class="btn btn-light" href=""><i class="bi bi-eye"></i> EVADA</a> -->
                  </div>
                </template>
                <template #content v-if="priseEnCharge">
                  <div
                    v-if="
                      priseEnCharge &&
                      priseEnCharge.cheminEmploi != null &&
                      priseEnCharge.consignes.length == 0
                    "
                    class="d- justify-content-between px-4"
                  >
                    <div>
                      <div class="mb-2">
                        <h6>
                          {{ de.prenom }} a été orienté vers l'emploi
                          <span> {{ priseEnCharge.cheminEmploi }} </span>
                        </h6>
                        <small
                          >Générer lui des consignes selon le modèle standard de l'emploi
                          <span class="fw-bold"> {{ priseEnCharge.cheminEmploi }} </span>
                          pour les
                          <span class="fw-bold" :severity="de.estPPI ? 'danger' : ''"
                            >{{ de.estPPI ? "PPI" : "INSTRUIT" }}s</span
                          >.</small
                        >
                      </div>
                      <a
                        class="btn btn-primary"
                        :disabled="readOnly"
                        @click.prevent="generationDesConsignes"
                        href="#"
                      >
                        Générer les consignes</a
                      >
                    </div>
                  </div>
                  <Consignes
                    v-else-if="priseEnCharge && priseEnCharge.consignes.length != 0"
                    ref="prescriptionEditor"
                    v-model="priseEnCharge.consignes"
                    :readOnly="readOnly"
                    :demandeurId="demandeurId"
                  />

                  <template v-if="false">
                    <div class="d-flex justify-content-between p-3 border-light mb-1">
                      <span>
                        <h6>1. Fiche unique de présentation de plan d'affaire</h6>
                        <small v-if="priseEnCharge.ficheUniquePresentationAffaire != null"
                          ><PTag
                            :severity="
                              priseEnCharge.ficheUniquePresentationAffaire ? '' : 'danger'
                            "
                            :value="
                              priseEnCharge.ficheUniquePresentationAffaire ? 'OUI' : 'NON'
                            "
                          ></PTag
                        ></small>
                        <small v-else>Non spécifié</small>
                      </span>
                      <div class="d- justify-content-between">
                        <PInputSwitch
                          v-model="priseEnCharge.ficheUniquePresentationAffaire"
                        />
                        <div>(OUI/NON)</div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between p-3 border-light mb-1">
                      <span>
                        <h6>2. Rédaction du plan d'appel à projet</h6>
                        <small v-if="priseEnCharge.redactionPlanAffaire != null"
                          ><PTag
                            :severity="priseEnCharge.redactionPlanAffaire ? '' : 'danger'"
                            :value="priseEnCharge.redactionPlanAffaire ? 'OUI' : 'NON'"
                          ></PTag
                        ></small>
                        <small v-else>Non spécifié</small>
                      </span>
                      <div class="d- justify-content-">
                        <PInputSwitch v-model="priseEnCharge.redactionPlanAffaire" />
                        <div>(OUI/NON)</div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between p-3 border-light mb-1">
                      <span>
                        <h6>3. Déploiement du projet dans l'espace et dans le temps</h6>
                        <small v-if="priseEnCharge.deploiementProjetEspaceTemps != null"
                          ><PTag
                            :severity="
                              priseEnCharge.deploiementProjetEspaceTemps ? '' : 'danger'
                            "
                            :value="
                              priseEnCharge.deploiementProjetEspaceTemps ? 'OUI' : 'NON'
                            "
                          ></PTag
                        ></small>
                        <small v-else>Non spécifié</small>
                      </span>
                      <div class="dustify-content-between">
                        <PInputSwitch
                          v-model="priseEnCharge.deploiementProjetEspaceTemps"
                        />
                        <div>(OUI/NON)</div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between p-3 border-light mb-1">
                      <span>
                        <h6>4. Recherche de financement</h6>
                        <small v-if="priseEnCharge.rechercheFinancement != null"
                          ><PTag
                            :severity="priseEnCharge.rechercheFinancement ? '' : 'danger'"
                            :value="priseEnCharge.rechercheFinancement ? 'OUI' : 'NON'"
                          ></PTag
                        ></small>
                        <small v-else>Non spécifié</small>
                      </span>
                      <div class="d- justify-content-">
                        <PInputSwitch v-model="priseEnCharge.rechercheFinancement" />
                        <div>(OUI/NON)</div>
                      </div>
                    </div>
                  </template>
                </template>
              </PCard>
              <PDivider />
              <PCard class="shadow-none mt-">
                <template #title>
                  <div class="d-flex justify-content-between">
                    <span
                      >4. Résultats
                      <PBadge>{{
                        priseEnCharge.structureEmbauche == null ? "NON" : "OUI"
                      }}</PBadge></span
                    >
                    <!-- <a class="btn btn-light" href=""><i class="bi bi-eye"></i> EVADA</a> -->
                    <TrouverEmploiEditor
                      ref="embauchEditor"
                      :item="priseEnCharge"
                      :title="'Information embauche'"
                      @save="saveEmbaucheInfo"
                    />
                    <a
                      v-if="!readOnly"
                      class="btn btn-outline-danger btn-sm"
                      @click.prevent="$refs.embauchEditor.show()"
                      href=""
                      ><i class="bi bi-pencil"></i
                    ></a>
                  </div>
                </template>
                <template #content v-if="priseEnCharge">
                  <template v-if="priseEnCharge.structureEmbauche != null">
                    <div class="d-flex justify-content-between p-3 border-light mb-1">
                      <span>
                        <h6>Le demandeur d'emploi a-t-il trouvé un emploi?</h6>
                        <small v-if="priseEnCharge.structureEmbauche != null"
                          ><PTag
                            :severity="
                              priseEnCharge.ficheUniquePresentationAffaire ? '' : 'danger'
                            "
                            :value="
                              priseEnCharge.ficheUniquePresentationAffaire ? 'OUI' : 'NON'
                            "
                          ></PTag
                        ></small>
                        <small v-if="priseEnCharge.structureEmbauche != null">
                          {{ priseEnCharge.structureEmbauche }}
                        </small>
                        <small v-else>Non spécifié</small>
                      </span>
                    </div>
                    <div class="d-flex justify-content-between p-3 border-light mb-1">
                      <span>
                        <h6>Quand avez trouvé cet emploi?</h6>
                        <small v-if="priseEnCharge.dateEmbauche != null">
                          {{ priseEnCharge.dateEmbauche }}
                        </small>
                        <small v-else>Non spécifié</small>
                      </span>
                      <div class="d- justify-content-">
                        <!-- <a class="btn btn-outline-danger btn-sm" @click.prevent="$refs.embauchEditor.show()" href=""><i class="bi bi-pencil"></i></a> -->
                      </div>
                    </div>
                  </template>
                  <div v-else>Non spécifié</div>
                </template>
                <div>
                  Initié par: <span>{{ priseEnCharge.initiateur }}</span>
                </div>
              </PCard>
            </div>
            <!-- </PTabView> -->
            <p-divider />
            <div
              class="d-flex bg-white"
              v-if="isReady && priseEnCharge != null && priseEnCharge.id != null"
            >
              <div v-if="priseEnCharge.creePar != null">
                <span class="text-muted">Initié par:</span>
                <small class="ms-2">{{ priseEnCharge.creePar.nomPrenom }}</small>
              </div>
              <div class="ms-5">
                <span class="text-muted">Date:</span>
                <small class="ms-2">{{
                  $moment(priseEnCharge.createdDate).format("DD/MM/YYYY")
                }}</small>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import ActiviteEditor from "../../../components/espace/common/ActiviteEditor";
import TrouverEmploiEditor from "../../../components/espace/common/prise-en-charge/TrouverEmploiEditor.vue";
import Prescriptions from "../../../components/espace/common/prise-en-charge/Prescriptions.vue";
import Consignes from "../../../components/espace/common/prise-en-charge/Consignes.vue";
import TextEditorDialog from "../../../components/espace/common/prise-en-charge/TextEditorDialog.vue";
// import FormDialog from '../../../components/espace/common/prise-en-charge/FormDialog'
import StatutProfesssionelSelectorDialog from "../../../components/espace/common/prise-en-charge/StatutProfesssionelSelectorDialog.vue";
// import EditorD from '../../../components/espace/common/ActiviteEditor'
import GrilleEvaluationDetail from "../../../components/espace/common/GrilleEvaluationDetail";
import ActiviteReportEditor from "../../../components/espace/common/ActiviteReportEditor.vue";
import BlockEmplois from "../../../components/espace/demandeur/cv/BlockEmplois.vue";
import BlockCompetences from "../../../components/espace/demandeur/cv/BlockCompetences.vue";
import DecisionSelectorDialog from "../../../components/espace/common/prise-en-charge/DecisionSelectorDialog.vue";
import PropositionMetier from "../../../components/espace/common/prise-en-charge/PropositionMetier.vue";
import CvDetailsDialog from "../../../components/espace/common/prise-en-charge/CvDetailsDialog.vue";
import DStepper from "../../../components/common/DStepper.vue";
import ActiviteRequisSelectorDialog from "../../../components/espace/common/prise-en-charge/editor/ActiviteRequisSelectorDialog.vue";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import DossierValidationDialog from "../../../components/espace/demandeur/DemandeurDossierValidationDialog.vue";
import DemandeurMutationDialog from "../../../components/espace/demandeur/mutation-demandeur/DemandeurMutationDialog.vue";
import GelerParcoursDemandeurDialog from "../../../components/espace/common/parcours-demandeur/editor/dialog/GelerParcoursDemandeurDialog.vue";

export default {
  props: {
    demandeurId: {
      type: [Number, String],
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    priseEnChargeId: {
      type: [Number, String],
      default: null,
    },
  },
  components: {
    ActiviteEditor,
    Prescriptions,
    Consignes,
    DStepper,
    // FormDialog,
    CvDetailsDialog,
    PropositionMetier,
    TextEditorDialog,
    TrouverEmploiEditor,
    BlockEmplois,
    BlockCompetences,
    StatutProfesssionelSelectorDialog,
    ActiviteReportEditor,
    GrilleEvaluationDetail,
    DecisionSelectorDialog,
    AzoliStatusBadge,
    ActiviteRequisSelectorDialog,
    DossierValidationDialog,
    DemandeurMutationDialog,
    GelerParcoursDemandeurDialog,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      formObject: {},

      activeEvent: {
        participations: [],
      },
      activites: [],
      propositionMetiers: [],
      activitePlanifies: [],
      loading: true,
      viewMode: "PRISE_EN_CHARGE",
      classement: {
        id: 1,
        createdDate: null,
        code: "DENA",
        libelle: "DENA",
        description: "Demandeur d'Emploi Non-Autonome",
        noteMin: 0,
        noteMax: 49,
      },
      demandeur: {},
      activeStepIndex: 0,
    };
  },
  created() {
    this.loading = true;
    this.fetchDossier({
      demandeurId: this.demandeurId,
    }).then(() => {});
    this.loadActivitesPlanifies({});
    this.loadPriseEncharge();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    dossier(val) {
      this.demandeur = { ...val };
      // this.activeStepIndex = 0
      // if(this.autonomieCompleted) this.activeStepIndex = 1
      // if(this.positionnementCompleted) this.activeStepIndex = 2
    },
    priseEnCharge() {
      this.activeStepIndex = 0;
      if (this.autonomieCompleted) this.activeStepIndex = 1;
      if (this.positionnementCompleted) this.activeStepIndex = 2;
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      // activitePlanifies: 'demandeur/activitePlanifie/activitePlanifies',
      // dossier: 'demandeur/demandeur',
      priseEnCharge: "demandeur/priseEnCharge",
    }),
    canModify() {
      return (
        this.priseEnCharge != null && this.priseEnCharge.statutParcoursDemandeur != "GELE"
      );
    },
    isReady() {
      return (
        this.de != null &&
        this.de.id != null &&
        this.priseEnCharge != null &&
        this.priseEnCharge.id != null
      );
    },
    isProfileKnown() {
      return (
        this.priseEnCharge != null &&
        this.priseEnCharge.classement != null &&
        this.priseEnCharge.classement.libelle != null
      );
    },
    deClassement() {
      return this.priseEnCharge != null &&
        this.priseEnCharge.classement != null &&
        this.priseEnCharge.classement.libelle != null
        ? this.priseEnCharge.classement.libelle
        : null;
    },
    getAtelierPPs() {
      return this.activitePlanifies.filter(
        (act) => act.typeActivite.code == "ATELIER_PP"
      );
    },
    getEntretienDiagnostics() {
      return this.activitePlanifies.filter(
        (act) => act.typeActivite.code == "ENTRETIEN_DIAGNOSTIC"
      );
    },
    steps() {
      return [
        {
          label: "DEGRE D'AUTONOMIE",
          subtitle: "Degre du demandeur",
          to: "#",
          completed: this.autonomieCompleted,
          command: () => {
            this.activeStepIndex = 0;
          },
        },
        {
          label: "POSITIONNEMENT",
          subtitle: "Positionnement du demandeur",
          to: "#",
          completed: this.positionnementCompleted,
          command: () => {
            if (this.autonomieCompleted) this.activeStepIndex = 1;
          },
        },
        {
          label: "PLAN D'ACTION",
          subtitle: "Plan action",
          to: "#",
          completed: this.priseEnCharge && this.priseEnCharge.structureEmbauche != null,
          command: () => {
            if (this.positionnementCompleted) this.activeStepIndex = 2;
          },
        },
        // {
        //     label: 'PLACEMENT',
        //     subtitle: 'Placement du demandeur',
        //     to: '#',
        //     command:() => {
        //     }
        // },
      ];
    },
    de() {
      return this.demandeur;
    },
    // demandeurId(){
    //   return this.$route.params.demandeurId
    // },
    autonomieCompleted() {
      return (
        this.priseEnCharge &&
        this.priseEnCharge.commentaireDegreAutonomie != null &&
        this.requiredModulesCompleted
      );
    },
    positionnementCompleted() {
      return (
        this.autonomieCompleted &&
        this.priseEnCharge &&
        this.priseEnCharge.cheminEmploi != null
      );
    },
    requiredModulesCompleted() {
      return (
        this.getAtelierPPs.length != 0 &&
        this.getAtelierPPs[0].statusActivite == "CLOTUREE"
      );
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateActivitePlanifie: "activitePlanifie/createOrUpdateActivitePlanifie",
      fetchActivitePlanifies: "demandeur/activitePlanifie/fetchActivitePlanifies",
      updateDemandeurCv: "demandeur/updateDemandeurCv",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      fetchDossier: "demandeur/fetchCardreDossier",
      fetchPriseEnCharge: "demandeur/fetchPriseEnCharge",
      validerDossier: "demandeur/validerDossier",
      updatedPriseEnCharge: "demandeur/updatedPriseEnCharge",
      updatePriseEnChargeCheminEmploi: "demandeur/updatePriseEnChargeCheminEmploi",
      genererPriseEnChargePrescritions: "demandeur/genererPriseEnChargePrescritions",
      genererPriseEnChargeConsignes: "demandeur/genererPriseEnChargeConsignes",
      appliquerMutationDemandeur: "demandeur/appliquerMutationDemandeur",
    }),
    activiteColor(activite) {
      let bg = "event-box-pending text-white";
      if (activite.statusActivite == "TENUE") {
        bg = "event-box-done text-white";
      } else if (activite.statusActivite == "NON_TENUE") {
        bg = "event-box-missed text-white";
      } else if (activite.statusActivite == "CLOTUREE") {
        bg = "event-box-reported text-white";
      }
      return bg;
    },
    handleCvChanged(cv) {
      this.demandeur = {};
      this.demandeur = { ...cv };
    },
    creationModeSelected(item) {
      this.activeEvent = {
        ...item,
        participants: [{ id: this.demandeurId }],
      };

      this.$refs.activiteEditor.show();
    },
    async saveGelerParcoursDemandeur(demandeur) {
      if (!this.checkCanModify()) return;
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment geler la mutation sur  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.appliquerMutationDemandeur({
            ...demandeur,
            demandeurId: this.demandeurId,
            priseEnChargeId: this.priseEnCharge.id,
          })
            .then((data) => {
              console.log(data);
              this.$toast.success("Mutation effectuée avec succès!", {
                position: "top-right",
                duration: 10000,
              });
              window.location.reload();
            })
            .catch((err) => {
              this.$toast.error("Une erreur s'est produite lors de l'opération!", {
                position: "top-right",
                duration: 10000,
              });
            });
        },
      });
    },
    async saveMutationDemandeur(demandeur) {
      if (!this.checkCanModify()) return;
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment appliquer la mutation sur  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.appliquerMutationDemandeur({
            ...demandeur,
            demandeurId: this.demandeurId,
            priseEnChargeId: this.priseEnCharge.id,
          })
            .then((data) => {
              console.log(data);
              this.$toast.success("Mutation effectuée avec succès!", {
                position: "top-right",
                duration: 10000,
              });
              window.location.reload();
            })
            .catch((err) => {
              this.$toast.error("Une erreur s'est produite lors de l'opération!", {
                position: "top-right",
                duration: 10000,
              });
            });
        },
      });
    },
    async showDemandeurTransformationDialog() {
      if (!this.checkCanModify()) return;
      this.$refs.demandeurMutationDialog.show();
    },
    async showGelerParcoursDemandeurDialog() {
      if (!this.checkCanModify()) return;
      this.$refs.gelerParcoursDemandeurDialog.show();
    },
    async showDossierValidationDialog(demandeur) {
      // this.demandeur = { ...demandeur }
      this.$refs.dossierValidator.show();
    },
    async validerFiche(demandeur) {
      if (demandeur.status == "VALIDE") {
        this.$toast.info(demandeur.estPPI ? "Fiche déjà validée!" : "CV déjà validé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      if (demandeur.status != "TERMINE") {
        this.$toast.error(demandeur.estPPI ? "Fiche non terminée" : "CV non terminé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      await this.$confirm.require({
        group: "confirmDialog",
        message: demandeur.estPPI
          ? `Voulez-vous vraiment valider  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}?`
          : `Voulez-vous vraiment valider le CV de ${demandeur.nom} ${demandeur.prenom}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerDossier({
            ...demandeur,
            status: "VALIDE",
          }).then((data) => {
            console.log(data);
            this.$toast.success("Fiche validée avec succès!", {
              position: "top-right",
              duration: 10000,
            });
            this.demandeur = {
              ...this.demandeur,
              status: "VALIDE",
              score: data.score,
            };
            window.location.reload();
          });
        },
      });
    },
    loadActivitesPlanifies() {
      this.fetchActivitePlanifies({
        extraUrlParam: `participations.participant.id=${this.demandeurId}`,
      }).then((data) => {
        this.activitePlanifies = [...data];
        // this.activites = data
      });
    },
    loadPriseEncharge() {
      this.loading = true;
      if (this.priseEnChargeId == null) {
        this.fetchPriseEnCharge({
          demandeurId: this.demandeurId,
        }).then(() => {
          this.loading = false;
        });
      } else {
        this.fetchPriseEnCharge({
          priseEnChargeId: this.priseEnChargeId,
        }).then(() => {
          this.loading = false;
        });
      }
    },
    createOrUpdateProgramme(activite) {
      if (!this.checkCanModify()) return;
      this.createOrUpdateActivitePlanifie(activite).then(() => {
        this.$toast.success(`Activité ${activite.titre} créée avec succès!`, {
          position: "top-right",
          duration: 8000,
        });
        this.loadActivitesPlanifies();
        this.resetActiviteEvent();
      });
    },
    createOrUpdateRapport(rapport) {
      if (!this.checkCanModify()) return;
      this.loadActivitesPlanifies();
      this.loadPriseEncharge();
      // this.activitePlanifies = this.activitePlanifies.map(act => {
      //   return act.id != rapport.activite && rapport.status == 'TERMINE'? act: { ...act, statusActivite: 'CLOTUREE' }
      // })
    },
    checkCanChangeCheminEmploi() {
      const canModify =
        this.isReady &&
        this.priseEnCharge.dateGenerationPrescription == null &&
        this.priseEnCharge.dateGenerationConsigne == null;
      if (!canModify) {
        this.$toast.error(
          "Vous ne pouvez pas changer le positionnement de ce DE parce que les prescriptions et/ou consignes ont déjà été générés!",
          {
            position: "top-right",
            duration: 10000,
          }
        );
      }
      return canModify;
    },
    decisionEditor() {
      if (!this.checkCanChangeCheminEmploi()) return;
      this.$refs.decisionSelector.show();
    },
    showErreurPriseEnChargeNonModifiable() {
      this.$toast.error(
        "Cette prise en charge est GELLE. Et ne peut donc pas modifiée!",
        {
          position: "bottom-right",
          duration: 18000,
        }
      );
    },
    checkCanModify() {
      if (!this.canModify) {
        this.showErreurPriseEnChargeNonModifiable();
      }
      return this.canModify;
    },
    generationDesPrescriptions() {
      if (!this.checkCanModify()) return;
      this.genererPriseEnChargePrescritions({
        demandeurId: this.demandeurId,
      }).then(() => {
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
        window.location.reload();
        // this.loadPriseEncharge();
      });
    },
    generationDesConsignes() {
      if (!this.checkCanModify()) return;
      this.genererPriseEnChargeConsignes({
        demandeurId: this.demandeurId,
      }).then(() => {
        this.loadPriseEncharge();
      });
    },
    updateDemandeurEmplois(emplois) {
      if (!this.checkCanModify()) return;
      console.log(emplois);
      this.updateDemandeurCv({
        ...this.demandeur,
        emplois,
      });
    },
    // updateDemandeurProgrammes(programmes){
    //   // this.demandeur.emplois = emplois
    //   console.log(programmes)
    //   this.priseEnCharge.programmes = programmes.map(prog => ({ ...prog, participant: this.demandeur }))
    //   this.updatedPriseEnCharge(this.priseEnCharge)
    // },
    savePropositionMetiers(metiers) {
      if (!this.checkCanModify()) return;
      this.updatedPriseEnCharge({
        ...this.priseEnCharge,
        propositionMetiers: [...metiers],
      });
    },
    saveEmbaucheInfo(priseEnCharge) {
      if (!this.checkCanModify()) return;
      this.updatedPriseEnCharge({
        ...this.priseEnCharge,
        ...priseEnCharge,
      });
    },
    updateStatutProfessionnel(statutProfessionnel) {
      if (!this.checkCanModify()) return;
      this.priseEnCharge.statutProfessionnel = statutProfessionnel;
      this.updatedPriseEnCharge(this.priseEnCharge);
    },
    updateCommentaireAutonomie(commentaire) {
      if (!this.checkCanModify()) return;
      this.priseEnCharge.commentaireDegreAutonomie = commentaire;
      this.updatedPriseEnCharge(this.priseEnCharge);
    },
    updateCommentairePlanAction(commentaire) {
      if (!this.checkCanModify()) return;
      this.priseEnCharge.commentairePlanAction = commentaire;
      this.updatedPriseEnCharge(this.priseEnCharge);
    },
    updateAppreciation(appreciation) {
      if (!this.checkCanModify()) return;
      this.priseEnCharge.appreciation = appreciation;
      this.updatedPriseEnCharge(this.priseEnCharge);
    },
    updateCheminEmploi(chemin) {
      if (!this.checkCanModify()) return;

      if (!this.checkCanChangeCheminEmploi()) return;

      this.priseEnCharge.cheminEmploi = chemin.value;
      this.updatePriseEnChargeCheminEmploi(this.priseEnCharge).then((data) => {
        this.priseEnCharge = {
          ...this.priseEnCharge,
          ...data,
        };

        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
        window.location.reload();
      });
    },
    setActiveEvent(activite) {
      this.activeEvent = { ...activite };
    },
    remplirRapport(activite, i) {
      this.setActiveEvent(activite);
      this.$refs.activiteReportEditor[i].show();
    },
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }

      this.$router.push(this.steps[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.steps[event.pageIndex - 1].to);
    },
    complete() {
      this.$toast.add({
        severity: "success",
        summary: "Order submitted",
        detail:
          "Dear, " +
          this.formObject.firstname +
          " " +
          this.formObject.lastname +
          " your order completed.",
      });
    },
    uploadFile(de) {
      console.log(de);
    },
    onProgress(e) {
      console.log(e);
    },
    getClassementColor(code) {
      let color = "primary";
      if (code == "DENA") {
        color = "danger";
      } else if (code == "DEA") {
        color = "success";
      }
      return color;
    },
    async ajouterProgramme(typeActiviteCode) {
      if (!this.checkCanModify()) return;
      this.resetActiviteEvent();
      if (typeActiviteCode != null) {
        await this.$refs.activiteEditor.selectTypeActiviteByCode(typeActiviteCode);
      }
      this.$refs.activiteEditor.show();
    },
    programmerModuleRequis() {
      if (!this.checkCanModify()) return;
      this.resetActiviteEvent();
      this.$refs.activiteRequisEditor.show();
    },
    resetActiviteEvent() {
      // this.activeEvent = {
      //   participations: []
      // }
    },
    updateOrCreateDemandeur(demandeur) {
      console.log(demandeur);
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style>
.p-card-body {
  padding: 0px 10px !important;
}
.p-card-content {
  padding: 0px !important;
}
</style>
